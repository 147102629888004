<template>
  <div class="page-root tools-root">
    <div class="tools-banner-wrapper">
      <div class="tools-banner">
        <div class="text">
          <div class="en">COMPREHENSIVE</div>
          <div class="zh">全面的应用工具服务 随时为您提供服务</div>
          <div class="en">SERVICE FOR YOU</div>
          <div class="desc hidden-lg-and-up">全方位解决金融机构二手车融资业务的难点，助力金融机构二手车业务的推广和开展</div>
          <div class="line"></div>
        </div>
        <div class="box"></div>
        <img src="@/assets/pexels-albin-berlin-919073@2x.png"/>
      </div>
    </div>
    <div class="tabs-wrapper">
      <div class="tab-item" v-for="(item, index) in tabList" :key="index" @click="goTo(item.url)">
        <img :src="require(`./assets/${item.name}.svg`)"/>
        <div>{{ item.name }}</div>
      </div>
      <div class="tab-item" @click="goToCheChe()">
        <img :src="require(`./assets/迁入标准查询.svg`)" />
        <div>更多查询服务</div>
      </div>
    </div>
    <div class="cars-wrapper">
      <div class="title">
        <div class="zh">车辆排行榜</div>
        <div class="en">RANKING OF VEHICLES</div>
      </div>
      <div class="car-tabs">
        <div class="car-list">
          <div class="title">热门车型TOP10</div>
          <div class="data">
            <div v-for="(item, index) in carList1" :key="index">
              <img class="img" v-if="index < 3" :src="require(`./assets/${index + 1}.svg`)"/><span
                v-else
                class="num"
            >{{ index + 1 }}</span
            ><span>{{ item.brandName }}&nbsp;&nbsp;&nbsp;</span
            ><span :title="item.vehicleTypeName">{{ item.vehicleTypeName }}</span>
            </div>
          </div>
        </div>
        <div class="car-list">
          <div class="title">车辆保值率TOP10</div>
          <div class="data">
            <div v-for="(item, index) in carList2" :key="index">
              <img class="img" v-if="index < 3" :src="require(`./assets/${index + 1}.svg`)"/><span
                v-else
                class="num"
            >{{ index + 1 }}</span
            ><span class="ellipsis">{{ item.brandName }}</span
            ><span>{{ item.vehicleSeriesName }}</span
            ><span style="float: right">三年保值率：{{ item.ratio }}%</span>
            </div>
          </div>
        </div>
        <div class="car-list">
          <div class="title">新车优惠幅度TOP10</div>
          <div class="data">
            <div v-for="(item, index) in carList3" :key="index">
              <img class="img" v-if="index < 3" :src="require(`./assets/${index + 1}.svg`)"/><span
                v-else
                class="num">{{ index + 1 }}</span>
                <span class="ellipsis">{{ item.brandName }}</span>
                <span class="ellipsis2">{{ item.seriesName }}</span>
                <span style="float: right">近30天折扣: {{ (item.bare * 100).toFixed(0) }}%</span>
            </div>
          </div>
        </div>
      </div>
      <div class="car-tabs-sm">
        <div class="title">
          <div :class="index == i ? 'hover' : ''" v-for="(item, i) in itemList" :key="item" @click="index = i">
            {{ item }}
          </div>
        </div>
        <div class="data" v-if="index == 0">
          <div v-for="(item, index) in carList1" :key="index">
            <img class="img" v-if="index < 3" :src="require(`./assets/${index + 1}.svg`)"/><span v-else class="num">{{
              index + 1
            }}</span
          ><span>{{ item.brandName }}&nbsp;&nbsp;&nbsp;</span
          ><span :title="item.vehicleTypeName">{{ item.vehicleTypeName }}</span>
          </div>
        </div>
        <div class="data" v-if="index == 1">
          <div v-for="(item, index) in carList2" :key="index">
            <img class="img" v-if="index < 3" :src="require(`./assets/${index + 1}.svg`)"/><span v-else class="num">{{
              index + 1
            }}</span
          ><span class="ellipsis">{{ item.brandName }}</span
          ><span>{{ item.vehicleSeriesName }}</span
          ><span style="float: right">三年保值率：{{ item.ratio }}%</span>
          </div>
        </div>
        <div class="data" v-if="index == 2">
          <div v-for="(item, index) in carList3" :key="index">
            <img class="img" v-if="index < 3" :src="require(`./assets/${index + 1}.svg`)"/><span v-else class="num">{{
              index + 1
            }}</span
            ><span class="ellipsis">{{ item.brandName }}</span
          ><span class="ellipsis2">{{ item.seriesName }}</span
          ><span style="float: right">近30天折扣: {{ (item.bare * 100).toFixed(0) }}%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import './styles/mobile.scss';
import './styles/pc/pc.scss';

import {get} from '@/utils/axios';

export default {
  name: 'Pinggufuwu',
  data() {
    return {
      index: 0,
      itemList: ['热门车型', '车辆保值率', '新车优惠幅度'],
      tabList: [
        {name: '快速评估', url: '/tools-pinggu'},
        {name: 'VIN精准评估', url: '/tools-vin'},
        {name: '保值率查询', url: '/tools-baozhi'},
        {name: '车型库查询', url: '/tools-chexing'},
        // {name: '迁入标准查询', url: '/tools-qianru'},
      ],
      carList1: [],
      carList2: [],
      carList3: [],
    };
  },
  methods: {
    goTo(url) {
      this.$router.push(url);
    },
    goToCheChe(){
      window.open('https://chechebj.com/cheshuju.html', '_blank');
    },
  },
  mounted() {
    get('/web/hotType?pageNum=1&pageSize=10').then((res) => {
      this.carList1 = res.rows;
    });
    get('/system/ratio/listAfter5Years?pageNum=1&pageSize=10').then((res) => {
      this.carList2 = res.rows;
    });
    get('/web/bareMonth').then((res) => {
      console.log(res)
      this.carList3 = res.data.filter(item => item.bare !== '0.0');
    });
  },
};
</script>
